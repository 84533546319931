import React from 'react';
import PropTypes from 'prop-types';

import LandingAdvice from '../pagesComponents/home/LandingAdvice';
import LandingAds from '../pagesComponents/home/LandingAds';

class LandingPageAds extends React.Component {
  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
    window.onbeforeunload = null;
  }

  render() {
    const { pageContext } = this.props;
    if (pageContext.landingPage === 'Advice') return <LandingAdvice pageContext={pageContext} />;
    return <LandingAds pageContext={pageContext} />;
  }
}

LandingPageAds.propTypes = {
  pageContext: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    city: PropTypes.string,
    locality: PropTypes.string,
    landingPage: PropTypes.string,
    fabric: PropTypes.string,
    piece: PropTypes.string,
    backgroundImage: PropTypes.shape({
      publicURL: PropTypes.string,
    }),
    mobileBackgroundImage: PropTypes.shape({
      publicURL: PropTypes.string,
    }),
    couturierBlock: PropTypes.shape({
      text1: PropTypes.string,
      text2: PropTypes.string,
      text2HasPersonalPronoun: PropTypes.bool,
      imageSrc: PropTypes.shape({
        publicURL: PropTypes.string,
      }),
    }),
    customerFeedbacks: PropTypes.arrayOf(PropTypes.shape({
      photo: PropTypes.shape({
        publicURL: PropTypes.string,
      }),
      name: PropTypes.string,
      rating: PropTypes.number,
      jobDescription: PropTypes.string,
      feedback: PropTypes.string,
    })),
    faqQuestions: PropTypes.arrayOf(PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string,
    })),
    brands: PropTypes.arrayOf(PropTypes.shape({
      img: PropTypes.shape({
        publicURL: PropTypes.string,
      }),
      index: PropTypes.number,
    })),
    preFilledSteps: PropTypes.shape({
      selectedCloth: PropTypes.string,
      selectedFabric: PropTypes.string,
    }),
    footNote: PropTypes.shape({
      title: PropTypes.string,
      paragraph: PropTypes.string,
      links: PropTypes.arrayOf(PropTypes.string),
      breadcrumbIntro: PropTypes.shape({
        paragraph: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default LandingPageAds;
