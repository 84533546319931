import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import withOrderContext from '../../withOrderContext';
import { Container, Content } from '../../components/home/v2/styledComponents';
import {
  Button, UpTitle, Title2, colors,
} from '../../components/home/v3/styledComponents';
import MenuBar from '../../components/home/MenuBar';
import Header from '../../components/home/v3/Header';
import BenefitsBanner from '../../components/home/v3/BenefitsBanner';
import HowItWorksProgressBar from '../../components/home/v3/HowItWorksProgressBar';
import LandingServices from '../../components/home/v3/LandingServices';
import FeedbacksBannerAds from '../../components/home/v3/FeedbacksBannerAds';
import FAQAds from '../../components/home/v3/FAQAds';
import Footer from '../../components/home/v3/Footer';
import Layout from '../../layouts/index';
import routesMap from '../../Routes';

import giftImg from '../../assets/benefits/gift.svg';
import sewingImg from '../../assets/benefits/sewing.svg';
import magnifyingGlassImg from '../../assets/benefits/magnifyingGlass.svg';
import conversationImg from '../../assets/benefits/conversation.svg';
import giftImgNavy from '../../assets/benefits/giftNavy.svg';
import sewingImgNavy from '../../assets/benefits/sewingNavy.svg';
import magnifyingGlassImgNavy from '../../assets/benefits/magnifyingGlassNavy.svg';
import conversationImgNavy from '../../assets/benefits/conversationNavy.svg';
import FootNote from '../../components/LandingPage/FootNote';
import PartnersPager from '../../components/home/v3/PartnersPager';

const HowItWorksContainer = styled.div`
  padding: 70px 0px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    padding: 20px 0px 15px;
  }
`;

const benefitsArray = [
  {
    img: magnifyingGlassImg,
    imgNavy: magnifyingGlassImgNavy,
    text1Desktop: 'Un diagnostic gratuit de',
    text2Desktop: 'votre garde robe',
    text1Mobile: 'Un diagnostic gratuit de',
    text2Mobile: 'votre garde robe',
  },
  {
    img: sewingImg,
    imgNavy: sewingImgNavy,
    text1Desktop: 'Couturiers expérimentés',
    text2Desktop: 'et triés sur le volet',
    text1Mobile: 'Couturiers expérimentés',
    text2Mobile: 'et triés sur le volet',
  },
  {
    img: conversationImg,
    imgNavy: conversationImgNavy,
    text1Desktop: '1h de conseil offerte pour',
    text2Desktop: 'vous initier à l’upcycling',
    text1Mobile: '1h de conseil offerte pour',
    text2Mobile: 'vous initier à l’upcycling',
  },
  {
    img: giftImg,
    imgNavy: giftImgNavy,
    text1Desktop: 'Possibilité de réaliser',
    text2Desktop: 'vos idées sous 3 jours',
    text1Mobile: 'Possibilité de réaliser',
    text2Mobile: 'vos idées sous 3 jours',
  },
];

class LandingAdvice extends React.Component {
  componentDidMount() {
    const { orderContext: { initialize } } = this.props;
    initialize();
  }

  goToStep2 = () => {
    const { pageContext: { preFilledSteps, city } } = this.props;
    navigate(routesMap.Step2.url, { state: { isAdviceOrder: true, preFilledSteps, city } });
  }

  render() {
    const {
      pageContext: {
        slug, preFilledSteps, backgroundImage, mobileBackgroundImage, customerFeedbacks, faqQuestions, footNote, brands,
      },
    } = this.props;
    return (
      <Layout routeSlug={slug}>
        <Container>
          <Content full hasNoBanner>
            <MenuBar showGiftBanner={false} />
            <Header
              title="1h gratuite de conseils à domicile avec votre couturier"
              hideNote
              smallTitle
              titleLeft
              adviceVersion
              preFilledSteps={preFilledSteps}
              backgroundImage={backgroundImage?.publicURL}
              mobileBackgroundImage={mobileBackgroundImage?.publicURL}
              goToStep2={this.goToStep2}
            />
            <BenefitsBanner benefitsArray={benefitsArray} />
            <HowItWorksContainer>
              <UpTitle>Votre rendez-vous</UpTitle>
              <Title2 marginBottom={75}>Comment ça marche ?</Title2>
              <HowItWorksProgressBar adviceVersion />
              <Button navy onClick={this.goToStep2}>Prendre rendez-vous</Button>
            </HowItWorksContainer>
            <LandingServices version="adviceVersion" />
            <FeedbacksBannerAds customerFeedbacks={customerFeedbacks} />
            <FAQAds faqQuestions={faqQuestions} adviceVersion preFilledSteps={preFilledSteps} />
            <FootNote
              title={footNote.title}
              paragraph={footNote.paragraph}
              links={footNote.links}
              breadcrumbIntro={footNote.breadcrumbIntro.paragraph}
              adviceVersion
              linkStyle={{ fontWeight: 700, color: colors.green }}
            />
            <HowItWorksContainer>
              <Button navy onClick={this.goToStep2}>Prendre rendez-vous</Button>
            </HowItWorksContainer>
            <PartnersPager brands={brands} adviceVersion />
            <Footer />
          </Content>
        </Container>
      </Layout>
    );
  }
}

LandingAdvice.propTypes = {
  pageContext: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    preFilledSteps: PropTypes.shape({}).isRequired,
    city: PropTypes.string,
    backgroundImage: PropTypes.shape({
      publicURL: PropTypes.string,
    }).isRequired,
    mobileBackgroundImage: PropTypes.shape({
      publicURL: PropTypes.string,
    }).isRequired,
    customerFeedbacks: PropTypes.arrayOf(PropTypes.shape({
      photo: PropTypes.shape({
        publicURL: PropTypes.string,
      }),
      name: PropTypes.string,
      rating: PropTypes.number,
      jobDescription: PropTypes.string,
      feedback: PropTypes.string,
    })).isRequired,
    faqQuestions: PropTypes.arrayOf(PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string,
    })).isRequired,
    footNote: PropTypes.shape({
      title: PropTypes.string,
      paragraph: PropTypes.string,
      links: PropTypes.arrayOf(PropTypes.string),
      breadcrumbIntro: PropTypes.shape({
        paragraph: PropTypes.string,
      }),
    }).isRequired,
    brands: PropTypes.arrayOf(PropTypes.shape({
      img: PropTypes.shape({
        publicURL: PropTypes.string,
      }),
      index: PropTypes.number,
    })).isRequired,
  }).isRequired,
  orderContext: PropTypes.shape({
    initialize: PropTypes.func.isRequired,
  }).isRequired,
};

export default withOrderContext(LandingAdvice);
