import React from 'react';
import PropTypes from 'prop-types';

import { Container, Content } from '../../components/home/v2/styledComponents';
import Header from '../../components/home/v3/Header';
import BenefitsBanner from '../../components/home/v3/BenefitsBanner';
import ACouturierAtHome from '../../components/home/v3/ACouturierAtHome';
import FeedbacksBannerAds from '../../components/home/v3/FeedbacksBannerAds';
import FAQAds from '../../components/home/v3/FAQAds';
import MenuBar from '../../components/home/MenuBar';
import PartnersPager from '../../components/home/v3/PartnersPager';
import Footer from '../../components/home/v3/Footer';
import Layout from '../../layouts/index';

const getTitle = (fabric, locality, piece) => fabric === 'curtains'
  ? 'Faites venir un couturier chez vous pour retoucher vos rideaux et voilages'
  : `Nos couturiers se déplacent chez vous, ${locality ? `à ${locality}` : ''} pour vos retouches ${piece ? `de ${piece}` : ''}`;

const getFormattedPreFilledSteps = (fabric, preFilledSteps) => {
  let formattedPreFilledSteps = fabric === 'curtains'
    ? { selectedCategory: 'deco', selectedPiece: 'ride', selectedTypeOfWork: 'alteration' }
    : { selectedCategory: 'clothes', selectedTypeOfWork: 'alteration' };
  if (preFilledSteps) formattedPreFilledSteps = { ...formattedPreFilledSteps, ...preFilledSteps };
  return formattedPreFilledSteps;
};

const LandingAds = ({
  pageContext: {
    slug, city, locality, fabric, piece, backgroundImage, mobileBackgroundImage,
    couturierBlock, customerFeedbacks, faqQuestions, brands, preFilledSteps: preFilledStepsProps,
  },
}) => {
  const title = getTitle(fabric, locality, piece);
  const preFilledSteps = getFormattedPreFilledSteps(fabric, preFilledStepsProps);
  return (
    <Layout routeSlug={slug}>
      <Container>
        <Content full hasNoBanner>
          <MenuBar showGiftBanner={false} />
          <Header
            title={title}
            hideNote
            smallTitle
            titleLeft
            curtainsNote={fabric === 'curtains'}
            photoVersion={fabric}
            curtainsVersion={fabric === 'curtains'}
            preFilledSteps={preFilledSteps}
            backgroundImage={backgroundImage?.publicURL}
            mobileBackgroundImage={mobileBackgroundImage?.publicURL}
          />
          <BenefitsBanner />
          <ACouturierAtHome curtainsVersion={fabric === 'curtains'} preFilledSteps={preFilledSteps} couturierBlock={couturierBlock} />
          <FeedbacksBannerAds curtainsVersion={fabric === 'curtains'} customerFeedbacks={customerFeedbacks} />
          <FAQAds faqQuestions={faqQuestions} curtainsVersion={fabric === 'curtains'} city={city} preFilledSteps={preFilledSteps} />
          <PartnersPager curtainsVersion={fabric === 'curtains'} brands={brands} />
          <Footer />
        </Content>
      </Container>
    </Layout>
  );
};

LandingAds.propTypes = {
  pageContext: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    city: PropTypes.string,
    locality: PropTypes.string,
    fabric: PropTypes.string,
    piece: PropTypes.string,
    backgroundImage: PropTypes.shape({
      publicURL: PropTypes.string,
    }),
    mobileBackgroundImage: PropTypes.shape({
      publicURL: PropTypes.string,
    }),
    couturierBlock: PropTypes.shape({
      text1: PropTypes.string,
      text2: PropTypes.string,
      text2HasPersonalPronoun: PropTypes.bool,
      imageSrc: PropTypes.shape({
        publicURL: PropTypes.string,
      }),
    }),
    customerFeedbacks: PropTypes.arrayOf(PropTypes.shape({
      photo: PropTypes.shape({
        publicURL: PropTypes.string,
      }),
      name: PropTypes.string,
      rating: PropTypes.number,
      jobDescription: PropTypes.string,
      feedback: PropTypes.string,
    })),
    faqQuestions: PropTypes.arrayOf(PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string,
    })),
    brands: PropTypes.arrayOf(PropTypes.shape({
      img: PropTypes.shape({
        publicURL: PropTypes.string,
      }),
      index: PropTypes.number,
    })),
    preFilledSteps: PropTypes.shape({
      selectedCloth: PropTypes.string,
      selectedFabric: PropTypes.string,
    }),
  }).isRequired,
};

export default LandingAds;
